import 'select2'
import Helpers from 'src/vendor/oneui/javascript/modules/helpers'

$(document).on('turbolinks:load', (e) => {
  $('select:not(.custom-select)').select2({
    width: 'style',
    theme: 'bootstrap4'
  })

  Helpers.run('core-bootstrap-tabs')
})
