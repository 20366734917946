import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr.js'
import { english } from 'flatpickr/dist/l10n/default.js'

const moment = require('moment')
// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      monthSelectorType: 'static',
      mode: 'range',
      dateFormat: 'd-m-Y',
      locale: language
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedDates, dateStr, instance) {
    const updateChartID = this.element.getAttribute('data-chart-id')

    if (selectedDates.length < 2) {
      return
    }

    const startDate = moment(selectedDates[0])
    const endDate = moment(selectedDates[1])

    const startDatetime = startDate.startOf('day').format()
    const endDatetime = endDate.endOf('day').format()

    const myData = {
      start_datetime: startDatetime,
      end_datetime: endDatetime
    }

    if (startDate != null && endDate != null) {
      if (updateChartID != null) {
        const chart = Chartkick.charts[updateChartID]
        if (chart != null) {
          const locationNodePathName = chart.getDataSource().split('?')[0]
          chart.updateData(
            `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
          )
        } else {
          console.log(
            `can not find chart with the spacific id: ${updateChartID}`
          )
        }
      }
    }
  }
}
