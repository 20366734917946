// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'controllers'

// Javascript imports
import 'src/javascripts/shared/globals'
import 'src/javascripts/user/turbolinks_prepare_for_cache'
import 'src/javascripts/user/report_results'
import 'src/javascripts/user/compartison_chart'

import App from 'src/vendor/oneui/javascript/app'
// const imagePath = (name) => images(name, true);

require('@rails/ujs').start()
require('turbolinks').start()
require('channels')
// require('@rails/activestorage').start();

require('chartkick')
require('chart.js')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
require.context('../images', true)

$(document).on('turbolinks:load', (e) => {
  window.OneUIApp = new App()

  $('[data-toggle="tooltip"]').tooltip()
})
