import { Controller } from 'stimulus'

import Chart from 'chart.js'
// import the plugin core
import 'chartjs-plugin-colorschemes/src/plugins/plugin.colorschemes'

// import a particular color scheme
import { SetThree12 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'
import 'chartjs-plugin-annotation'

// import a particular color scheme
import {
  buildBoxAnnotation,
  buildLineAnnotation
} from 'src/javascripts/shared/chart_utils'

export default class extends Controller {
  static targets = ['canvas'];

  connect () {
    this.chart = this.initializeCategoryChart()
    this.updateChartData()
  }

  disconnect () {
    this.chart.destroy()
  }

  baseConfig = (titles, type, options = {}) => ({
    type,
    data: [],
    options: {
      responsive: true,
      maintainAspectRatio: true,

      elements: {
        line: {
          fill: false
        },
        point: {
          radius: this.adjustRadiusBasedOnData,
          hoverRadius: this.adjustRadiusBasedOnData
        },
        rectangle: {}
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false
            },
            scaleLabel: {
              display: true,
              labelString: titles.xTitle,
              fontSize: 16,
              fontColor: '#333'
            }
          }
        ],
        yAxes: [
          {
            offset: true,
            gridLines: {
              drawOnChartArea: false
            },
            scaleLabel: {
              display: true,
              labelString: titles.yTitle,
              fontSize: 16,
              fontColor: '#333'
            },
            ticks: {
              beginAtZero: false
            }
          }
        ]
      },
      plugins: {
        annotation: {
          annotations: []
        },
        colorschemes: {
          scheme: SetThree12,
          override: false
        }
      }
    }
  });

  initializeCategoryChart = () => {
    const type = this.data.get('type') || 'line'
    const title = this.data.get('title') || ''
    const xTitle = this.data.get('xTitle')
    const yTitle = this.data.get('yTitle')
    // const options = this.data.get('options') || {}

    const chart = new Chart(
      this.canvasTarget,
      this.baseConfig({ chartTitle: title, xTitle, yTitle }, type)
    )
    return chart
  };

  updateChartData = () => {
    const controller = this
    const url = this.getDatasourceURL()
    const { chart } = this
    Rails.ajax({
      url,
      type: 'GET',
      success (response) {
        const chartData = controller.responseToChartData(response.data)
        const boxAnnotations = response.boxAnnotations.map(buildBoxAnnotation)
        const lineAnnotations = response.lineAnnotations.map(
          buildLineAnnotation
        )
        try {
          chart.data = chartData
          chart.options.plugins.annotation.annotations.push(...boxAnnotations)
          chart.options.plugins.annotation.annotations.push(...lineAnnotations)
          chart.update()
        } catch (err) {
          controller.chartError('Malformed data')
          throw err
        }
      },
      error (response) {
        controller.chartError(response.error.message)
      }
    })
  };

  responseToChartData = (data) => {
    const chartData = {}
    chartData.labels = data.labels
    chartData.datasets = data.datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      lineTension: 0
    }))
    return chartData
  };

  adjustRadiusBasedOnData = (ctx) => {
    const label = ctx.chart.data.labels[ctx.dataIndex]
    return label === this.getHighlitedPoint() ? 10 : 5
  };

  getDatasourceURL = () => this.data.get('datasourceUrl');

  getHighlitedPoint = () => this.data.get('highlightDataId');

  chartError = (message) => {
    this.element.textContent = `Error Loading Chart Data: ${message}`
    this.element.style.color = '#ff0000'
    this.element.style.textAlign = 'center'
    this.element.style.lineHeight = '300px'
  };
}
