const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
const randomByte = () => randomNumber(0, 255)

export const randomCssRgba = (opacity) => `rgba(${[randomByte(), randomByte(), randomByte(), opacity].join(',')})`

const annotationBoxValid = (annotation) => annotation.yMin != null &&
  annotation.yMax != null &&
  annotation.value != null

const annotationLineValid = (annotation) => annotation.value != null

const colorValues = ['#f7fcfd', '#e0ecf4', '#bfd3e6', '#9ebcda', '#8c96c6']

export const buildBoxAnnotation = (annotation) => {
  if (annotationBoxValid(annotation) === false) return {}

  return {
    type: 'box',
    drawTime: 'beforeDatasetsDraw',
    id: `a-box-${annotation.value}`,
    xScaleID: 'x-axis-0',
    yScaleID: 'y-axis-0',
    value: annotation.value,

    yMax: annotation.yMax,
    yMin: annotation.yMin,

    borderWidth: 0,
    borderColor: colorValues[annotation.value - 1],
    backgroundColor: colorValues[annotation.value - 1],
    label: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      position: 'center',
      enabled: true,
      content: annotation.label
    }
  }
}

export const buildLineAnnotation = (annotation) => {
  if (annotationLineValid(annotation) === false) return {}
  return {
    type: 'line',
    mode: 'horizontal',
    drawTime: 'beforeDatasetsDraw',
    scaleID: annotation.axis === 'y' ? 'y-axis-0' : 'x-axis-0',
    value: annotation.value,
    borderWidth: 3,
    borderDash: [2, 2],
    borderDashOffset: 5,
    borderColor: '#fc8d59',
    label: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      content: annotation.label,
      position: 'left',
      yAdjust: 0,
      enabled: true
    }
  }
}
