import { Controller } from 'stimulus'

export default class extends Controller {
  initialize () {
    if (this.activeDropdownNavigationItem && this.activeDropdownMenu) {
      this.activeDropdownMenu.classList.add('open')
    }
  }

  disconnect () {
    if (this.activeDropDown) {
      this.activeDropDown.classList.remove('open')
    }
  }

  // private
  get activeDropdownNavigationItem () {
    return this.element.querySelector('a.nav-main-link.active')
  }

  get navBar () {
    return this.activeDropdownNavigationItem.closest('.nav-main')
  }

  get activeDropdownMenu () {
    return this.activeDropdownNavigationItem.closest('.nav-main-item.dropdown')
  }
}
