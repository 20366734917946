import Chart from 'chart.js'

const randomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)
const randomByte = () => randomNumber(0, 255)
const randomCssRgba = (opacity) => `rgba(${[randomByte(), randomByte(), randomByte(), opacity].join(',')})`

const config = {
  type: 'radar',
  data: {
    labels: [
      'Dulac Hotel',
      'Grand Serai',
      'Metropolis',
      'Anemolia Resort & Spa',
      'Essense',
      'Byzantio',
      'Its Kale Boutique'
    ],
    datasets: [
      {
        backgroundColor: randomCssRgba(0.3),
        borderColor: randomCssRgba(0.3),
        pointBackgroundColor: randomCssRgba(0.3),
        label: 'Περιβαλλοντικό Αποτύπωμα',
        data: [80, 40, 60, 40, 50, 70, 55]
      }
    ]
  },
  options: {
    legend: {
      position: 'top'
    },
    scale: {
      ticks: {
        beginAtZero: true
      }
    }
  }
}

$(document).on('turbolinks:load', (e) => {
  if (document.getElementById('environmental_footprint_comparison')) {
    window.myRadar = new Chart('environmental_footprint_comparison', config)
  }
})
