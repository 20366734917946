import { Controller } from 'stimulus'
import Chart from 'chart.js'

import { SetThree12 } from 'chartjs-plugin-colorschemes/src/colorschemes/colorschemes.brewer'
import 'chartjs-plugin-annotation'

export default class extends Controller {
  static targets = ['canvas', 'chartContainer'];

  connect () {
    this.chart = this.initializeCategoryChart()
    const localData = this.getLocalData()
    if (localData) {
      this.updateChartFromLocalData()
    } else {
      this.updateChartFromRemoteSource()
    }
  }

  disconnect () {
    this.chart.destroy()
  }

  baseConfig = (title) => ({
    type: 'radar',
    data: [],
    options: {
      responsive: true,
      maintainAspectRatio: true,
      elements: {
        point: {
          radius: this.adjustRadiusBasedOnData,
          hoverRadius: this.adjustRadiusBasedOnData
        }
      },
      title: {
        display: false,
        text: title,
        fontSize: 20,
        fontColor: '#333'
      },
      legend: {
        position: 'right'
      },
      scale: {
        ticks: {}
      },
      plugins: {
        colorschemes: {
          scheme: SetThree12
        }
      }
    }
  });

  initializeCategoryChart = () => {
    const title = this.data.get('title') || ''

    const chart = new Chart(this.canvasTarget, this.baseConfig(title))
    return chart
  };

  updateChartFromRemoteSource = () => {
    const controller = this
    const url = this.getDatasourceURL()
    this.chartContainerTarget.classList.add('block-mode-loading')

    Rails.ajax({
      url,
      type: 'GET',
      success (response) {
        controller.chartContainerTarget.classList.remove('block-mode-loading')
        controller.updateChartData(
          controller.responseToChartData(response.data)
        )
      },
      error (response) {
        controller.chartContainerTarget.classList.remove('block-mode-loading')
        controller.chartError(response.error.message)
      }
    })
  };

  updateChartFromLocalData = () => {
    const data = this.getLocalData()
    const parsedData = JSON.parse(data)
    this.chartContainerTarget.classList.add('block-mode-loading')
    this.updateChartData(parsedData)
    this.chartContainerTarget.classList.remove('block-mode-loading')
  };

  responseToChartData = (data) => {
    const chartData = {}
    chartData.labels = data.labels
    chartData.datasets = data.datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      fill: false
    }))
    return chartData
  };

  updateChartData = (data) => {
    const controller = this
    const { chart } = this
    try {
      chart.data = data
      chart.update()
    } catch (err) {
      controller.chartError('Malformed data')
      throw err
    }
  };

  getDatasourceURL = () => this.data.get('datasourceUrl');

  getLocalData = () => this.data.get('localData');

  getHighlitedPoint = () => this.data.get('highlightDataId');

  adjustRadiusBasedOnData = (ctx) => {
    const label = ctx.chart.data.labels[ctx.dataIndex]
    return label === this.getHighlitedPoint() ? 10 : 5
  };

  chartError = (message) => {
    this.element.textContent = `Error Loading Chart Data: ${message}`
    this.element.style.color = '#ff0000'
    this.element.style.textAlign = 'center'
    this.element.style.lineHeight = '300px'
  };
}
