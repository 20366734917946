import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
import { Greek } from 'flatpickr/dist/l10n/gr.js'
import { english } from 'flatpickr/dist/l10n/default.js'

const moment = require('moment')

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const locale = this.element.getAttribute('data-locale')
    const language = locale === 'en' ? english : Greek

    this.config = {
      time_24hr: true,
      noCalendar: true,
      enableTime: true,
      locale: language
    }
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  change (selectedTimes, dateStr, instance) {
    const updateChartID = this.inputTarget.getAttribute('data-chart-id')

    const dateTimePickers = this.element.parentElement
    const selectedDate = dateTimePickers.querySelector('.day-picker').querySelector('input').value
    const startTime = dateTimePickers.querySelector('.start-time-picker').querySelector('input').value
    const endTime = dateTimePickers.querySelector('.end-time-picker').querySelector('input').value

    const startDatetime = moment(selectedDate, 'D-M-YYYY')
    const startHour = moment(startTime, 'H m')
    startDatetime.set({
      hour: startHour.get('hour'),
      minute: startHour.get('minute')
    })

    const endDatetime = moment(selectedDate, 'D-M-YYYY')
    const endHour = moment(endTime, 'H m')
    endDatetime.set({
      hour: endHour.get('hour'),
      minute: endHour.get('minute')
    })

    if (updateChartID != null && startDatetime.isValid() && endDatetime.isValid()) {
      const chart = Chartkick.charts[updateChartID]
      const myData = { start_datetime: startDatetime.format(), end_datetime: endDatetime.format() }
      if (chart != null) {
        const locationNodePathName = chart.getDataSource().split('?')[0]
        chart.updateData(
          `${locationNodePathName}?${new URLSearchParams(myData).toString()}`
        )
      } else {
        console.log(
          `can not find chart with the spacific id: ${updateChartID}`
        )
      }
    }
  }
}
